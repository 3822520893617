import React from 'react';

import SEO from '../components/SEO';
import TextPage from '../components/TextPage';

const FaqPage = props => (
  <TextPage location={props.location}>
    <SEO title="私隱政策" />

    <h1>私隱政策</h1>

    <p>自2017年8月1日其正式生效</p>
    <p>
      您好，歡迎閱讀我們的私隱政策。Roomyr Asset Management Limited（下稱「Easy
      Roomyr」或「我們」）承諾致力保護您的私隱。我們重視您對我們的信任並把您的個人資料交給我們。我們承諾採取所有合理的預防措施，及遵從所有適用於香港不定時修訂有關保障個人資料的法例及規定，來保護您的個人資料不被濫用及作不正當的用途。我們會定期檢討及可能隨時修改本私隱政策，並更新這個頁面。如果在本私隱政策的任何修訂後繼續使用我們的服務，則代表您同意修訂後的私隱政策。請仔細閱讀以下更多有關本私隱政策及個人資料的處理。
    </p>

    <h4>1. 個人資料之收集</h4>
    <p>1.1 我們重視閣下的私隱，並已採取措施確保我們不向閣下收集多於就向閣下提供服務及保障閣下帳戶所需的資料。</p>
    <p>
      1.2
      當閣下瀏覽我們的網站及使用我們的服務時，我們可能要求閣下提供個人資料。我們可能要求閣下提供的個人資料包括而不限於：姓名、出生日期、電話號碼、電郵地址及住宅或工作地址（「個人資料」）。
    </p>
    <p>
      1.3
      請注意閣下必須提供某些類別的個人資料（在收集時會列明）。假如閣下未能提供任何被標記為必須提供的個人資料，我們可能無法為閣下提供我們的產品或服務。
    </p>

    <h4>2. 收集個人資料的目的及用途</h4>
    <p>2.1 假如閣下提供任何個人資料予我們，即閣下授權我們收集、保留及使用閣下之個人資料，以被用於下列目的：</p>
    <p>2.1.1 評估、核實及管理閣下的個人資料；</p>
    <p>2.1.2 提供客戶服務予閣下及回覆閣下的查詢、意見或要求；</p>
    <p>2.1.3 進行研究或統計分析，以改善我們的產品和服務，及作市場推廣和宣傳之用；</p>
    <p>2.1.4 提供我們的網站之登入帳號予閣下；</p>
    <p>2.1.5 辦理關於任何該等資料，產品及服務的付款手續；</p>
    <p>2.1.6 經辦閣下向他人或他人向閣下就本公司及服務作出的任何轉介；</p>
    <p>2.1.7 直接營銷；及</p>
    <p>2.1.8 就上述任何目的，或依照法律要求，或就閣下提出的任何意見或要求而作出任何披露。</p>

    <h4>3. 轉移個人資料</h4>
    <p>
      3.1 閣下同意我們可披露及轉移閣下之個人資料予我們的附屬公司，聯營公司，
      業務夥伴及就以下服務所聘用的第三方服務提供者： (a)
      資料輸入服務；(b)管理客戶資料庫服務；(c)與客戶聯繫服務（而服務提供者可能會就宣傳推廣、客戶服務、或其他目的聯絡閣下）及(d)安全處置資料服務（「第三方服務提供者」）。此等第三方服務提供者均對我們負有保密責任，及僅獲許可就以上第2.1條所指明之目的，而非就該等第三方服務提供者本身目的（包括直接促銷），使用閣下之個人資料。
    </p>
    <p>
      3.2
      當有必要時我們亦可能會為上述第2.1.8條所指明之目的披露及轉移閣下之個人資料予我們所聘用的專業顧問、執法機關、追收欠款公司、保險公司、政府和監管機構及其他組織。
    </p>
    <p>
      3.3
      我們不會於未經您同意下披露或轉移您的個人資料。您可以在適當的位置表明您喜好，或可發送電郵至info@roomyr.com要求修改您的選擇（不另收費）。我們會遵從香港不定時修訂的法例及規定，以保護我們所披露或轉移的個人資料。
    </p>
    <p>
      3.4
      我們可能會就不同目的（包括市場推廣和宣傳）將總體資料在閣下身份不會被識別的情況下提供予第三方，及可能使用此資料以便更了解閣下的需要及改善和調整我們的產品和服務。
    </p>
    <p>
      3.5
      就以上第2.1條所述之任何目的或因與此等目的有關，閣下提供的任何個人資料將由我們保留，並可由我們的員工、任何我們所聘用之第三方服務提供者及以上第3.2條所提及之第三方查閱。
    </p>
    <p>
      3.6 顧客可根據需要選擇訂閱我們促銷郵件。若您不希望繼續接收促銷郵件，請點擊郵件中含有的取消訂閱鏈接，以取消訂閱。
    </p>

    <h4>4. 直接營銷</h4>
    <p>4.1 我們將不定時使用您的個人資料作直接營銷用途（下稱「直銷活動」），包括：</p>
    <p>4.1.1 推廣我們的產品、服務、活動、競賽、推廣優惠、特別節目及任何其他我們認為相關的資訊；</p>
    <p>4.1.2 推廣我們供應商、合作伙伴或第三方服務供應商的產品、服務、活動、競賽、推廣優惠及特別節目；及</p>
    <p>4.1.3 進行市場研究及評估。</p>
    <p>
      4.2
      我們將根據您所提供的個人資料以不同渠道向您提供有關直銷活動詳情，包括廣告、郵寄、電話、電郵或傳送短訊或訊息到您的無線裝置或手提電話。
    </p>
    <p>
      4.3
      在未取得您的同意之前，我們將不會使用您的個人資料作直銷活動用途。您可以在適當的位置表明您喜好，或可發送電郵至info@roomyr.com[email
      protected]�的選擇（不另收費）。
    </p>

    <h4>5. 保密</h4>
    <p>5.1 由我們持有的個人資料，會根據此私隱政策作保密處理。</p>
    <p>
      5.2
      除個人資料外，任何由訪客傳送或登載於我們網站或網站其中任何部分的問題、評語、建議或資料均被視作在非保密及非專有的基礎上自願提供予我們。我們保留在其他地方自由使用、複製、披露、傳送、刊載、廣播及／或登載該等資料的權利，包括將該等資料提供給任何關聯公司，例如為產品及服務的開發、製造和推廣及為滿足顧客的需要。
    </p>

    <h4>6. 信息保護</h4>
    <p>
      6.1
      我們將盡一切合理努力確保我們持有的所有個人資料均被儲存於穩妥和安全的地方，及只有獲授權之員工或獲授權的第三方服務提供者才可查閱。
    </p>

    <h4>7. 第三方網站</h4>
    <p>
      本私隱政策僅適用於我們及我們的網站，而不適用於任何其他第三方（包括他們營運的網站）。當點擊連結及/或廣告標語把您連線到第三方的網站或與我們關聯公司的網站，您將會受到他們的私隱政策約束。雖然我們支持互聯網私隱的保護，我們將不會承擔由我們網絡以外第三方所採取的任何行動。
    </p>

    <h4>8. 使用Cookies</h4>
    <p>
      當您瀏覽我們的網站時，我們使用Cookies來自動收集您的信息（如果您正在使用的瀏覽器和操作系統，及互聯網服務提供商的域名）。Cookies是一些信息，當您瀏覽網站時，瀏覽器將會儲存於電腦內。我們的Cookies識別您的電腦，讓我們能夠個人化您的購物體驗，及在您再次瀏覽網站時識別您。從Cookies收集的信息將被用於：
    </p>
    <p>8.1 使安全措施更為嚴密；</p>
    <p>8.2 使網上顧客可繼續瀏覽網頁；</p>
    <p>8.3 可用作分析網頁資料的使用量及瀏覽量</p>
    <p>8.4 為顧客提供個人化的網站；及</p>
    <p>8.5 建立網上顧客或訪客的簡介。</p>
    <p>
      大部分瀏覽器被設定為自動接受Cookies。您可自行改變瀏覽器的設定來拒絶接受Cookies。若您選擇拒絶接受Cookies，您可能無法使用我們網站上的所有功能和服務。
    </p>

    <h4>9. 查閱及更正您的資料</h4>
    <p>
      9.1
      根據香港私隱條例，閣下可要求查閱、驗證、更新或糾正、或要求索取屬於您個人資料的副本。閣下如欲索取個人資料副本或更正其中任何資料，可發送電郵至info@roomyr.com
      。
    </p>
    <p>
      9.2
      我們會先核實您的身份，才處理您的請求。根據香港私隱條例，我們保留權利就處理任何查閱或更正個人資料的要求向閣下收取合理的費用。我們定努力在合理時間內回複。
    </p>

    <h4>10. 修正</h4>
    <p>
      我們將不時修正或更新此私隱政策，並於我們的網站公布，詳情請瀏覽：www.roomyr.com我們鼓勵您不時瀏覽本私隱政策，以確保您得悉最新版本。
    </p>
    <p>感謝您閱讀我們的私隱政策。希望您能盡情享受我們的服務。</p>
  </TextPage>
);

export default FaqPage;
