import React from 'react';

import Layout from '../Layout';

import styles from './index.module.scss';

const TextPage = ({ location, children }) => (
  <Layout className={styles.root} location={location} navAlwaysFixed>
    <div className={styles.wrapper}>{children}</div>
  </Layout>
);

export default TextPage;
